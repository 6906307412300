@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Inter", sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;
}
